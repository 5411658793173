import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ContactUsButton from '../../components/contact-us-button.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

import postData from '../common/post_data.js';
import urls from '../common/urls.js';

const CustomInfoDiv = styled(InfoDiv)`
  height: 10vh;
`;

const CustomInfoHeader = styled(InfoHeader)`
  float: left;
  width: 30%;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: auto;
  height: 15vh;
  border-radius: 25px;
`;

const Input = styled.input`
  margin-left: 10px;
  name: quantity;
  placholder: 0;
  step: 1;
  width: 100px;
`;

const CustomInfoTextDiv = styled(InfoTextDiv)`
  font-family: Montserrat;
  color: #000;
  font-size: 25px;
  display: inline-block;
  width: 70%;
`;

const FormInfoHeader = styled.div`
  float: left;
  position: relative;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 25px;
  color: #000;
`;

const FormButton = styled.div`
  position: relative;
  margin: 0 auto;
  float: right;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
`;

function Part(props) {
  console.log('props in Part');
  console.log(props);

  const [quantityValue, setQuantityValue] = useState('');

  const postPartItemToOrder = (event) => {
    event.preventDefault();
    const data = {
      key: event.target.key.value,
      name: event.target.name.value,
      serial_number: event.target.serial_number.value,
      description: event.target.description.value,
      quantity: event.target.quantity.value,
    };

    postData(urls.parts.order.place_item, data)
      .then((res) => {
        if (res.status != 200) {
          console.log('Place Item status');
          console.log(res.status);
        }
      })
      .catch((err) => {
        console.log('Place Item error');
        console.log(err);
      });
  };

  return (
    <div>
      <CustomInfoDiv>
        <InfoTextDiv style={{ width: '100%' }}>
          <CustomInfoHeader>{props.name}</CustomInfoHeader>
          <CustomInfoTextDiv>
            <Form autoComplete="off" method="post" onSubmit={postPartItemToOrder}>
              <div>
                <input id="key" name="key" type="hidden" value={props._key} />

                <input id="name" name="name" type="hidden" value={props.name} />

                <label htmlFor="serial_number">
                  Serial number: {props.serial_number}
                  <input id="serial_number" name="serial_number" type="hidden" value={props.serial_number} />
                </label>

                <label htmlFor="description">
                  Description: {props.description}
                  <input id="description" name="description" type="hidden" value={props.description} />
                </label>
                <br></br>

                <label htmlFor="quantity">Price: {props.price}</label>
                <br></br>

                <label htmlFor="quantity">
                  Quantity:
                  <input
                    style={{
                      'margin-left': '15px',
                      width: '40px',
                    }}
                    id="quantity"
                    name="quantity"
                    type="text"
                    placeholder="0"
                    value={quantityValue}
                    onChange={(e) => setQuantityValue(e.target.value)}
                  />
                </label>
              </div>

              <ContactUsButton type="submit" disabled={quantityValue == ''}>
                Submit
              </ContactUsButton>
            </Form>
          </CustomInfoTextDiv>
        </InfoTextDiv>
      </CustomInfoDiv>
      <BlankSpace></BlankSpace>
      <CustomBlankSpace></CustomBlankSpace>
    </div>
  );
}

export default Part;
