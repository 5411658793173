import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

function Services(props) {
  const [servicesData, setServicesData] = useState(null);

  const [isServicesLoading, setServicesLoading] = useState(true);

  useEffect(() => {
    getData(urls.services.services, setServicesData, setServicesLoading);
  }, []);

  const servicesFillIn = (
    <InfoDiv>
      <ImgDiv></ImgDiv>
      <InfoTextDiv>
        <InfoText>
          <p>
            <div></div>
          </p>
        </InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );

  if (isServicesLoading) return servicesFillIn;

  if (!servicesData) return servicesFillIn;

  return (
    <InfoDiv>
      <ImgDiv>
        <Image fileName={servicesData[0].img.src} alt={servicesData[0].img.alt} className={servicesData[0].img.type} />
      </ImgDiv>
      <InfoTextDiv>
        <InfoHeader>{servicesData[0].text.header}</InfoHeader>
        <InfoText>
          <p>
            <div>{servicesData[0].text.subheader}</div>
            <ul style={{ columns: '2' }}>
              <li>{servicesData[0].text.body.services[0]}</li>
              <li>{servicesData[0].text.body.services[1]}</li>
              <li>{servicesData[0].text.body.services[2]}</li>
              <li>{servicesData[0].text.body.services[3][0]}</li>
              <ul>
                <li>{servicesData[0].text.body.services[3][1]}</li>
                <li>{servicesData[0].text.body.services[3][2]}</li>
                <li>{servicesData[0].text.body.services[3][3]}</li>
              </ul>
              <li>{servicesData[0].text.body.services[4][0]}</li>
              <ul>
                <li>{servicesData[0].text.body.services[4][1]}</li>
                <li>{servicesData[0].text.body.services[4][2]}</li>
                <li>{servicesData[0].text.body.services[4][3]}</li>
              </ul>
              <li>{servicesData[0].text.body.services[5]}</li>
              <li>{servicesData[0].text.body.services[6]}</li>
              <li>{servicesData[0].text.body.services[7]}</li>
            </ul>
            <div className="textblockFooter">
              <span>{servicesData[0].text.footer}</span>
            </div>
          </p>
        </InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );
}

export default Services;
