import styled from 'styled-components';

const Div = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 5vh;
  padding-top: 50px;
  background: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const InfoDiv = styled.div`
  position: absolute;
  display: inline-block;
`;

const Header = styled.p`
  font-family: Montserrat;
  font-size: 40px;
  color: #000;
  font-weight: bold;
`;

const BlankSpace = styled.div`
  width: 100%;
  height: 5vh;
`;

export { 
  Div, 
  InfoDiv, 
  Header, 
  BlankSpace 
};

