import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Image from '../common/image.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

const LandingContainer = styled.div`
  background: #fff;
  border-bottom: 10px solid #eee;
  border-top: 1px solid #eee;
`;

const LandingDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 20vh;
  background: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const LandingInfoDiv = styled.div`
  position: absolute;
  display: inline-block;
`;

function Landing(props) {
  const [landingData, setLandingData] = useState(null);

  const [isLandingLoading, setLandingLoading] = useState(true);

  useEffect(() => {
    getData(urls.home.landing, setLandingData, setLandingLoading);
  }, []);

  const landingFillIn = (
    <LandingContainer>
      <LandingDiv>
        <LandingInfoDiv></LandingInfoDiv>
      </LandingDiv>
    </LandingContainer>
  );

  if (isLandingLoading) return landingFillIn;

  if (!landingData) return landingFillIn;

  return (
    <LandingContainer>
      <LandingDiv>
        <LandingInfoDiv>
          <Image
            fileName={landingData[0].imgs[0].src}
            alt={landingData[0].imgs[0].alt}
            className={landingData[0].imgs[0].type}
          />
        </LandingInfoDiv>
      </LandingDiv>
    </LandingContainer>
  );
}

export default Landing;
