import React, { useEffect, useState } from 'react';

import { Div, InfoDiv, BlankSpace } from '../../common/subtext.js';

import Subtext from './subtext.js';
import HomeCarousel from './carousel.js';
import ScheduleButton from '../../common/schedule_button.js';
import Contact from '../../common/contact.js';

import getData from '../../common/fetch_data.js';
import urls from '../../common/urls.js';

function LandingBody(props) {
  const [data, setData] = useState(null);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getData(urls.home.body, setData, setLoading);
  }, []);

  const landingBodyFillIn = (
    <div>
      <div>
        <Div>
          <InfoDiv></InfoDiv>
        </Div>
        <Div>
          <InfoDiv></InfoDiv>
        </Div>
        <BlankSpace></BlankSpace>
      </div>
      <HomeCarousel></HomeCarousel>
      <div>
        <Div>
          <InfoDiv></InfoDiv>
        </Div>
        <Div>
          <InfoDiv></InfoDiv>
        </Div>
        <BlankSpace></BlankSpace>
      </div>
      <ScheduleButton></ScheduleButton>
      <Contact></Contact>
    </div>
  );

  if (isLoading) return landingBodyFillIn;

  if (!data) return landingBodyFillIn;

  return (
    <div>
      <Subtext subtext_a={data[0].text.body.subtext_one.a} subtext_b={data[0].text.body.subtext_one.b}></Subtext>

      <HomeCarousel></HomeCarousel>

      <Subtext subtext_a={data[0].text.body.subtext_two.a} subtext_b={data[0].text.body.subtext_two.b}></Subtext>
      <ScheduleButton></ScheduleButton>
      <Contact></Contact>
    </div>
  );
}

export default LandingBody;
