import styled from 'styled-components';

const Div = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 5vh;
  padding-top: 50px;
  background: #eee;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const InfoDiv = styled.div`
  position: absolute;
  display: inline-block;
  width: 90%;
`;

const Header = styled.p`
  font-family: Montserrat;
  font-size: 55px;
  color: #000;
  font-weight: bold;
  text-align: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const BlankSpace = styled.div`
  width: 100%;
  height: 5vh;
`;

export {
  Div,
  InfoDiv,
  Header,
  ButtonDiv,
  BlankSpace
};
