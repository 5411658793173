import React from 'react';
import styled from 'styled-components';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Container, SubContainer, Div } from '../common/one_column.js';

import { BlankSpace } from '../common/subtext.js';

const localizer = dayjsLocalizer(dayjs);

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

const booked_full = [
  {
    start: new Date(2024, 10, 20, 12, 3, 0),
    end: new Date(2024, 10, 20, 14, 3, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
  {
    start: new Date(2024, 10, 19, 4, 2, 0),
    end: new Date(2024, 10, 19, 12, 3, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
];

const open_times = [
  {
    start: new Date(2024, 10, 10),
    end: new Date(2024, 10, 12, 0, 0, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
  {
    start: new Date(2024, 10, 19, 4, 2, 0),
    end: new Date(2024, 10, 19, 12, 3, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
];

const open_min = new Date(0, 0, 0, 7, 0, 0);
const open_max = new Date(0, 0, 0, 18, 0, 0);

function ScheduleCalendar(props) {
  return (
    <Container style={{ border: '1px solid pink', height: '40vh' }}>
      <SubContainer style={{ height: '100vh' }}>
        <Div style={{ height: '100vh' }}>
          <Calendar
            localizer={localizer}
            views={['work_week']}
            defaultView={'work_week'}
            events={booked_full}
            min={open_min}
            max={open_max}
            style={{ width: '100%', height: '40vh' }}
          />
        </Div>
      </SubContainer>
    </Container>
  );
}

export default ScheduleCalendar;
