import React, { useState, Component } from 'react';
import styled from 'styled-components';

import { Div, InfoDiv, Header, BlankSpace } from '../../common/subtext.js';

function Subtext(props) {
  return (
    <div>
      <Div>
        <InfoDiv>
          <Header>{props.subtext_a}</Header>
        </InfoDiv>
      </Div>
      <Div>
        <InfoDiv>
          <Header>{props.subtext_b}</Header>
        </InfoDiv>
      </Div>
      <BlankSpace></BlankSpace>
    </div>
  );
}

export default Subtext;
