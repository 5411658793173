import React from 'react';

import NavigationBar from '../common/navbar.js';
import ScheduleHeader from './header.js';
import ScheduleCalendar from './calendar.js';
import ScheduleBooking from './booking.js';
import Footer from '../common/footer.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';

function SchedulePage(props) {
  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody style={{ height: '135vh' }}>
          <ScheduleHeader></ScheduleHeader>
          <ScheduleBooking></ScheduleBooking>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default SchedulePage;
