import React from 'react';
import styled from 'styled-components';

import { Div, InfoDiv, BlankSpace } from '../common/subtext.js';

const Header = styled.p`
  font-family: Montserrat;
  font-size: 55px;
  color: #000;
  font-weight: bold;
`;

const rentals = {
  text: {
    header: 'Rental Trucks',
  },
};

function RentalTrucks(props) {
  return (
    <div>
      <Div>
        <InfoDiv>
          <Header>{rentals.text.header}</Header>
        </InfoDiv>
      </Div>
      <BlankSpace></BlankSpace>
    </div>
  );
}

export default RentalTrucks;
