import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ContactUsButton from '../../components/contact-us-button';

import { Div, InfoDiv, Header, ButtonDiv, BlankSpace } from './button.js';

import getData from './fetch_data.js';
import urls from './urls.js';

console.log('services schedule schedule');
console.log(urls.services.schedule.schedule_link);

function ScheduleButton(props) {
  const [scheduleButtonData, setScheduleButtonData] = useState(null);

  const [isScheduleButtonLoading, setScheduleButtonLoading] = useState(true);

  useEffect(() => {
    getData(urls.component.schedule_button, setScheduleButtonData, setScheduleButtonLoading);
  }, []);

  const scheduleButtonFillIn = (
    <div style={{ background: '#fff' }}>
      <Div>
        <InfoDiv></InfoDiv>
      </Div>
      <Div>
        <InfoDiv>
          <Link to={urls.services.schedule.schedule_link} replace={false}>
            <ButtonDiv>
              <ContactUsButton></ContactUsButton>
            </ButtonDiv>
          </Link>
        </InfoDiv>
      </Div>
      <BlankSpace style={{ background: '#eee' }}></BlankSpace>
    </div>
  );

  if (isScheduleButtonLoading) return scheduleButtonFillIn;

  if (!scheduleButtonData) return scheduleButtonFillIn;

  return (
    <div style={{ background: '#fff' }}>
      <Div>
        <InfoDiv>
          <Header>{scheduleButtonData[0].text.header}</Header>
        </InfoDiv>
      </Div>
      <Div>
        <InfoDiv>
          <Link to={urls.services.schedule.schedule_link} replace={false}>
            <ButtonDiv>
              <ContactUsButton>{scheduleButtonData[0].text.body.label}</ContactUsButton>
            </ButtonDiv>
          </Link>
        </InfoDiv>
      </Div>
      <BlankSpace style={{ background: '#eee' }}></BlankSpace>
    </div>
  );
}

export default ScheduleButton;
