import React from 'react';
import styled from 'styled-components';

import Image from './image.js';

const InfoDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  height 40vh;
  margin-left: 15%;
  padding-top: 50px;
`;

const ImgDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 35%;
  float: left;
`;

function CarouselSlide(props) {

    console.log("carouselslide => ");
    console.log(props);

  return (
    <InfoDiv>
      <ImgDiv>
        <Image
          fileName={props.img_one.src}
          alt={props.img_one.alt}
          className={props.img_one.type}
        />
      </ImgDiv>
      <ImgDiv>
        <Image
          fileName={props.img_two.src}
          alt={props.img_two.alt}
          className={props.img_two.type}
        />
      </ImgDiv>
    </InfoDiv>
  );
}

export default CarouselSlide;
