import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from '../common/carousel.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

const ContainerDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  background: #eee;
  height: 30vh;
`;

function AboutCarousel(props) {
  const [aboutCarouselData, setAboutCarouselData] = useState(null);

  const [isAboutCarouselLoading, setAboutCarouselLoading] = useState(true);

  useEffect(() => {
    getData(urls.about.carousel, setAboutCarouselData, setAboutCarouselLoading);
  }, []);

  const carouselFillIn = <ContainerDiv></ContainerDiv>;

  if (isAboutCarouselLoading) return carouselFillIn;

  if (!aboutCarouselData) return carouselFillIn;

  return <Carousel data={aboutCarouselData[0].imgs}></Carousel>;
}

export default AboutCarousel;
