import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Image from '../common/image.js';

import { InfoDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import getData from './fetch_data.js';
import urls from './urls.js';

const ImgDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 35%;
  float: left;
  padding-top: 100px;
`;

const InfoTextDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 50%;
  float: left;
  padding-left: 50px;
  padding-top: 50px;
`;

function Hours(props) {
  const [hoursData, setHoursData] = useState(null);

  const [isHoursLoading, setHoursLoading] = useState(true);

  useEffect(() => {
    getData(urls.component.hours, setHoursData, setHoursLoading);
  }, []);

  const hoursFillIn = (
    <InfoDiv>
      <ImgDiv></ImgDiv>
      <InfoTextDiv>
        <InfoText></InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );

  if (isHoursLoading) return hoursFillIn;

  if (!hoursData) return hoursFillIn;

  return (
    <InfoDiv>
      <ImgDiv>
        <Image fileName={hoursData[0].img.src} alt={hoursData[0].img.alt} className={hoursData[0].img.class_name} />
      </ImgDiv>
      <InfoTextDiv>
        <InfoHeader>{hoursData[0].text.header}</InfoHeader>
        <InfoText>
          <p>
            <div>{hoursData[0].text.subheader}</div>
            <ul id="hours list" name="hours list">
              <li>{hoursData[0].text.body.monday}</li>
              <li>{hoursData[0].text.body.tuesday}</li>
              <li>{hoursData[0].text.body.wednesday}</li>
              <li>{hoursData[0].text.body.thursday}</li>
              <li>{hoursData[0].text.body.friday}</li>
              <li>{hoursData[0].text.body.saturday}</li>
              <li>{hoursData[0].text.body.sunday}</li>
            </ul>
            <div>
              <i>{hoursData[0].text.footer}</i>
            </div>
          </p>
        </InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );
}

export default Hours;
