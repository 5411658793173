import React, { useEffect, useState } from 'react';

import NavigationBar from '../common/navbar.js';
import CatalogHeader from './catalog_header.js';
import Catalog from './catalog.js';
import Footer from '../common/footer.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';

function PartsPage(props) {
  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody>
          <CatalogHeader></CatalogHeader>
          <Catalog></Catalog>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default PartsPage;
