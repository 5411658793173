import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ContactUsButton from '../../components/contact-us-button.js';
import Part from '../Parts/part.js';

/*import services from '../../assets/services.png';*/

import { Container, SubContainer, Div } from '../common/one_column.js';

import { InfoHeader, InfoText } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

import getData from '../common/fetch_data.js';
import postData from '../common/post_data.js';
import urls from '../common/urls.js';

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

const CartDiv = styled.div`
  width: 45%;
  float: left;
  height: 70vh;
  margin-top: 2.5vh;
  margin-left: 2.5%;
  border-radius: 25px;
  background: #fff;
  overflow: auto;
`;

const PayDiv = styled.div`
  width: 45%;
  float: right;
  height: 70vh;
  margin-top: 2.5vh;
  margin-right: 2.5%;
  border-radius: 25px;
  background: #fff;
`;

const CartHeader = styled.div`
  width: 45%;
  float: left;
  height: 8vh;
  margin-top: 1vh;
  margin-left: 2.5%;
  text-align: center;
  vertical-align: middle;
  border-radius: 25px;
  background: #fff;
  padding-top: 1vh;
`;

const PayHeader = styled.div`
  width: 45%;
  float: right;
  height: 8vh;
  margin-top: 1vh;
  margin-right: 2.5%;
  text-align: center;
  vertical-align: middle;
  border-radius: 25px;
  background: #fff;
  padding-top: 1vh;
`;

const D = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 40vh;
  padding-top: 2.5vh;
`;

const D2 = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 40vh;
  padding-top: 5vh;
`;

const D3 = styled.div`
  width: 80%;
  height: 31.5vh;
  position: relative;
  margin: 0 auto;
  padding-top: 2.5vh;
`;

const D4 = styled.div`
  width: 80%;
  border: 2px solid #eee;
  height: 10vh;
  position: relative;
  margin: 0 auto;
  border-radius: 25px;
  margin-top: 1vh;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  box-sizing: border-box;
  align: middle,
  margin-left: 15px,
`;

const Label = styled.label`
  width: 100%;
`;

function ShoppingCart(props) {
  const navigation = useNavigate();

  const [cartData, setCartData] = useState(null);

  const [isCartLoading, setCartLoading] = useState(true);

  const [firstNameValue, setFirstNameValue] = useState('');

  const [lastNameValue, setLastNameValue] = useState('');

  const [streetAddressValue, setStreetAddressValue] = useState('');

  const [cityValue, setCityValue] = useState('');

  const [provinceStateValue, setProvinceStateValue] = useState('');

  const [countryValue, setCountryValue] = useState('');

  const [postalCodeValue, setPostalCodeValue] = useState('');

  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    getData(urls.parts.order.get_cart, setCartData, setCartLoading);
  }, []);

  if (isCartLoading) return <p>Cart Loading</p>;

  if (!cartData) return <p>Cart Data incoming</p>;

  const postItemDeletion = (event) => {
    event.preventDefault();

    const data = {
      key: event.target.key.value,
      serial_number: event.target.serial_number.value,
    };

    console.log('-----');
    console.log('data');
    console.log(data);

    postData(
      urls.parts.order.remove_cart_item,
      data,
    ); /*.then((res) => {
            if (res.status != 200) {
                console.log("Place Order status");
                console.log(res.status);
            } 
        }).catch(err => {
            console.log("Place Order error");
            console.log(err);
        });*/
  };

  const postOrderDetails = (event) => {
    event.preventDefault();
    const _shipping_details = {
      first_name: event.target.first_name.value,
      last_name: event.target.last_name.value,
      address: event.target.street_address.value,
      city: event.target.city.value,
      province_state: event.target.province_state.value,
      country: event.target.country.value,
      postal_code: event.target.postal_code.value,
      phone_number: event.target.phone_number.value,
    };

    const data = {
      shopping_cart: cartData[0].shopping_cart,
      shipping_details: _shipping_details,
    };

    if (cartData[0].shopping_cart.length == 0) {
      navigation('/parts');
    } else {
      postData(urls.parts.order.place_order, data)
        .then((res) => {
          if (res.status != 200) {
            console.log('Place Order status');
            console.log(res.status);
          }
          navigation('/parts/order/transaction');
        })
        .catch((err) => {
          console.log('Place Order error');
          console.log(err);
        });
    }
  };

  return (
    <Container style={{ height: '100vh' }}>
      <SubContainer style={{ height: '90vh' }}>
        <Div style={{ background: '#eee' }}>
          <div style={{ width: '100%', height: '10vh' }}>
            <CartHeader>
              <InfoHeader>Shopping Cart Contents</InfoHeader>
            </CartHeader>
            <PayHeader>
              <InfoHeader>Shipping Details</InfoHeader>
            </PayHeader>
          </div>
          <div
            style={{
              width: '100%',
              'font-family': 'Montserrat',
              'font-size': '25px',
              color: '#000',
              display: 'inline-block',
            }}
          >
            <form autoComplete="off" method="post" onSubmit={postOrderDetails}>
              <CartDiv>
                {cartData[0].shopping_cart.map((item, i) => (
                  <D4 key={i}>
                    <input id="key" name="key" type="hidden" value={item.key} />

                    <label
                      style={{
                        'padding-left': '25px',
                        'padding-top': '25px',
                      }}
                      htmlFor="serial_number"
                    >
                      Serial number: {item.serial_number}
                      <input id="serial_number" name="serial_number" type="hidden" value={item.serial_number} />
                    </label>
                    <br></br>

                    <label
                      style={{
                        'padding-left': '25px',
                      }}
                      htmlFor="description"
                    >
                      Description: {item.description}
                      <input id="description" name="description" type="hidden" value={item.description} />
                    </label>
                    <br></br>

                    <label
                      style={{
                        'padding-left': '25px',
                        'padding-bottom': '25px',
                      }}
                      htmlFor="quantity"
                    >
                      Quantity: {item.quantity}
                      <input id="quantity" name="quantity" type="hidden" value={item.quantity} />
                    </label>
                  </D4>
                ))}
              </CartDiv>

              <PayDiv>
                <D>
                  <Label>
                    First Name
                    <Input
                      id="first_name"
                      name="first_name"
                      type="text"
                      value={firstNameValue}
                      placeholder="John"
                      onChange={(e) => setFirstNameValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Last Name
                    <Input
                      id="last_name"
                      name="last_name"
                      type="text"
                      value={lastNameValue}
                      placeholder="Smith"
                      onChange={(e) => setLastNameValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Street Address
                    <Input
                      id="street_address"
                      name="street_address"
                      type="text"
                      value={streetAddressValue}
                      placeholder="99 example road"
                      onChange={(e) => setStreetAddressValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    City
                    <br></br>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      value={cityValue}
                      placeholder="Winnipeg"
                      onChange={(e) => setCityValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Province/State
                    <Input
                      id="province_state"
                      name="province_state"
                      type="text"
                      value={provinceStateValue}
                      placeholder="Manitoba"
                      onChange={(e) => setProvinceStateValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Country
                    <Input
                      id="country"
                      name="country"
                      type="text"
                      value={countryValue}
                      placeholder="Canada"
                      onChange={(e) => setCountryValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Postal Code
                    <Input
                      id="postal_code"
                      name="postal_code"
                      type="text"
                      value={postalCodeValue}
                      placeholder="X#X #X#"
                      onChange={(e) => setPostalCodeValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <Label>
                    Phone Number
                    <Input
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      value={phoneNumberValue}
                      placeholder="123-456-7890"
                      onChange={(e) => setPhoneNumberValue(e.target.value)}
                    ></Input>
                  </Label>
                  <br></br>

                  <ContactUsButton
                    disabled={
                      firstNameValue == '' ||
                      lastNameValue == '' ||
                      streetAddressValue == '' ||
                      cityValue == '' ||
                      provinceStateValue == '' ||
                      countryValue == '' ||
                      postalCodeValue == '' ||
                      phoneNumberValue == ''
                    }
                    type="submit"
                  >
                    Submit
                  </ContactUsButton>
                </D>
              </PayDiv>
            </form>
          </div>
        </Div>
        <CustomBlankSpace></CustomBlankSpace>
      </SubContainer>
    </Container>
  );
}

export default ShoppingCart;
