import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import OrderButton from './order_button.js';
import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

const CustomInfoDiv = styled(InfoDiv)`
  height: 10vh;
  padding-bottom: 50px;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

function CatalogHeader(props) {
  const [catalogHeaderData, setCatalogHeaderData] = useState(null);

  const [isCatalogHeaderLoading, setCatalogHeaderLoading] = useState(true);

  useEffect(() => {
    getData(urls.parts.catalog_header, setCatalogHeaderData, setCatalogHeaderLoading);
  }, []);

  const catalogHeaderFillIn = (
    <div>
      <CustomInfoDiv>
        <ImgDiv></ImgDiv>
        <InfoTextDiv></InfoTextDiv>
      </CustomInfoDiv>
      <BlankSpace></BlankSpace>
      <OrderButton></OrderButton>
      <CustomBlankSpace></CustomBlankSpace>
    </div>
  );

  if (isCatalogHeaderLoading) return catalogHeaderFillIn;

  if (!catalogHeaderData) return catalogHeaderFillIn;

  return (
    <div>
      <CustomInfoDiv>
        <ImgDiv>
          <Image
            fileName={catalogHeaderData[0].img.src}
            alt={catalogHeaderData[0].img.alt}
            className={catalogHeaderData[0].img.type}
          />
        </ImgDiv>
        <InfoTextDiv>
          <InfoHeader>{catalogHeaderData[0].text.header}</InfoHeader>
        </InfoTextDiv>
      </CustomInfoDiv>
      <BlankSpace></BlankSpace>
      <OrderButton></OrderButton>
      <CustomBlankSpace></CustomBlankSpace>
    </div>
  );
}

export default CatalogHeader;
