import { useEffect, useState } from 'react';

const useImage = (file_name) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                console.log("useImage file_name => "+file_name);
                console.log(`${file_name}`);
                console.log('../../assets/img/'+`${file_name}`);
                
                const response = await import('../../assets/img/'+`${file_name}`)
                setImage(response.default)
            } catch(err) {
                console.log(err);
                setError(err)
            } finally {
                setLoading(false)
            }
        }
        fetchImage()
    }, [file_name])

    return {
        loading,
        error,
        image,
    }
}

export default useImage;
