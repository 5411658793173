import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import ContactUsButton from '../../components/contact-us-button.js';
/*import services from '../../assets/services.png';*/
import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import { Container, SubContainer, Div } from '../common/one_column.js';

import { BlankSpace } from '../common/subtext.js';

import getData from '../common/fetch_data.js';
import postData from '../common/post_data.js';
import urls from '../common/urls.js';

const localizer = dayjsLocalizer(dayjs);

const CustomInfoDiv = styled(InfoDiv)`
  height: 15vh;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

const Label = styled.label`
  width: 100%;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  box-sizing: border-box;
  align: middle,
  margin-left: 15px,
`;

const D = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 40vh;
  padding-top: 2.5vh;
`;

const services_header = {
  type: 'header',
  page: 'schedule',
  img: {
    src: 'services.png',
    alt: 'Services - Pic One',
    type: 'schedule_header',
  },
  text: {
    header: 'Book An Appointment',
    subheader: 'Provide the necessary information to reserve a bay',
  },
};
const booked_full = [
  {
    start: new Date(2024, 10, 20, 12, 3, 0),
    end: new Date(2024, 10, 20, 14, 3, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
  {
    start: new Date(2024, 10, 19, 4, 2, 0),
    end: new Date(2024, 10, 19, 12, 3, 0),
    title: 'Bays occupied',
    bgColor: '#ff7f50',
  },
];
const open_min = new Date(0, 0, 0, 7, 0, 0);
const open_max = new Date(0, 0, 0, 18, 0, 0);

function ScheduleBooking(props) {
  const navigation = useNavigate();

  const [appointmentData, setAppointmentData] = useState(null);

  const [isAppointmentLoading, setAppointmentLoading] = useState(true);

  const [firstNameValue, setFirstNameValue] = useState('');

  const [lastNameValue, setLastNameValue] = useState('');

  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const [emailValue, setEmailValue] = useState('');

  const [companyNameValue, setCompanyNameValue] = useState('');

  const [startDateValue, setStartDateValue] = useState('');

  const [startTimeValue, setStartTimeValue] = useState('');

  const [tooManyHoursValue, setTooManyHoursValue] = useState(false);

  const postSchedulingDetails = (event) => {
    event.preventDefault();
    const data = {
      company_name: event.target.company_name.value,
      first_name: event.target.first_name.value,
      last_name: event.target.last_name.value,
      phone_number: event.target.phone_number.value,
      email: event.target.email.value,
      start_date: event.target.start_date.value,
      start_time: event.target.start_time.value,
    };

    postData(urls.services.schedule.booking, data)
      .then((res) => {
        console.log('res from post');
        console.log(res);
        setTooManyHoursValue(res.too_many_hours);
        getData(urls.services.schedule.get_appointments, setAppointmentData, setAppointmentLoading).then((obj) => {
          console.log('obj from get_appointments');
          console.log(obj);
          console.log(appointmentData);
        });

        navigation('/services/schedule');
      })
      .catch((err) => {
        console.log('Place Order error');
        console.log(err);
      });
  };

  useEffect(() => {
    getData(urls.services.schedule.get_appointments, setAppointmentData, setAppointmentLoading);
  }, []);

  if (isAppointmentLoading) return <p>Services Loading</p>;

  if (!appointmentData) return <p>Services Data incoming</p>;

  return (
    <div>
      <Container style={{ height: '48vh' }}>
        <SubContainer style={{ height: '115vh' }}>
          <Div style={{ height: '115vh' }}>
            <Calendar
              localizer={localizer}
              views={['work_week']}
              defaultView={'work_week'}
              events={appointmentData.map((item) => {
                return {
                  start: new Date(item.start),
                  end: new Date(item.end),
                  title: item.title,
                };
              })}
              min={open_min}
              max={open_max}
              style={{ width: '100%', height: '50vh' }}
            />
          </Div>
        </SubContainer>
      </Container>
      <CustomBlankSpace></CustomBlankSpace>

      <div style={{ height: '55vh' }}>
        <CustomInfoDiv
          style={{
            'padding-top': '1px',
          }}
        >
          <InfoTextDiv
            style={{
              'margin-left': '200px',
              width: '30%',
            }}
          >
            <InfoHeader>{services_header.text.header}</InfoHeader>
            <InfoText>
              <p>{services_header.text.subheader}</p>
            </InfoText>

            <div style={{ float: 'left', width: '100%', height: '10vh' }}>
              <p style={{ color: '#ee4b2b', 'font-family': 'Montserrat', 'font-size': '25px' }}></p>
            </div>
          </InfoTextDiv>

          <InfoTextDiv style={{}}>
            <div
              style={{
                width: '100%',
                'font-family': 'Montserrat',
                'font-size': '25px',
                color: '#000',
                display: 'inline-block',
              }}
            >
              <form autoComplete="off" method="post" onSubmit={postSchedulingDetails}>
                <D>
                  <Label>
                    Company Name
                    <br></br>
                    <Input
                      id="company_name"
                      name="company_name"
                      type="text"
                      value={companyNameValue}
                      placeholder="TB Fleet Services"
                      onChange={(e) => setCompanyNameValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    First Name
                    <br></br>
                    <Input
                      id="first_name"
                      name="first_name"
                      type="text"
                      value={firstNameValue}
                      placeholder="John"
                      onChange={(e) => setFirstNameValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    Last Name
                    <br></br>
                    <Input
                      id="last_name"
                      name="last_name"
                      type="text"
                      value={lastNameValue}
                      placeholder="Smith"
                      onChange={(e) => setLastNameValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    Phone Number
                    <Input
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      value={phoneNumberValue}
                      placeholder="123-456-7890"
                      onChange={(e) => setPhoneNumberValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    Email
                    <br></br>
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      value={emailValue}
                      placeholder="example@email.com"
                      onChange={(e) => setEmailValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    Start Date
                    <br></br>
                    <Input
                      id="start_date"
                      name="start_date"
                      type="text"
                      value={startDateValue}
                      placeholder="YY/MM/DD"
                      onChange={(e) => setStartDateValue(e.target.value)}
                    ></Input>
                  </Label>

                  <Label>
                    Start Time
                    <br></br>
                    <Input
                      id="start_time"
                      name="start_time"
                      type="text"
                      value={startTimeValue}
                      placeholder="07:00 - 05:00"
                      onChange={(e) => setStartTimeValue(e.target.value)}
                    ></Input>
                  </Label>

                  <ContactUsButton
                    type="submit"
                    disabled={
                      firstNameValue == '' ||
                      lastNameValue == '' ||
                      phoneNumberValue == '' ||
                      emailValue == '' ||
                      companyNameValue == '' ||
                      startDateValue == '' ||
                      startTimeValue == ''
                    }
                  >
                    Submit
                  </ContactUsButton>
                </D>
              </form>
            </div>
          </InfoTextDiv>
        </CustomInfoDiv>
      </div>
    </div>
  );
}

export default ScheduleBooking;
