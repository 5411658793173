import React, { useEffect, useState } from 'react';
import Part from './part.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

function Catalog(props) {
  const [catalogData, setCatalogData] = useState(null);

  const [isCatalogLoading, setCatalogLoading] = useState(true);

  useEffect(() => {
    getData(urls.parts.catalog, setCatalogData, setCatalogLoading);
  }, []);

  if (isCatalogLoading) return <p>Catalog Loading</p>;

  if (!catalogData) return <p>Catalog Data incoming</p>;

  return (
    <div>
      {catalogData.map((item, i) => (
        <Part
          key={i}
          _key={item.key}
          name={item.name}
          serial_number={item.serial_number}
          description={item.description}
          price={item.price}
        ></Part>
      ))}
    </div>
  );
}

export default Catalog;
