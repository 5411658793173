import React, { useEffect, useState } from 'react';

import NavigationBar from '../common/navbar.js';
import Landing from './landing.js';
import LandingBody from './landing_body/body.js';
import Footer from '../common/footer.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

function LandingPage(props) {
  /*const [
        landingData, 
        setLandingData
    ] = useState(null)

    const [
        isLandingLoading, 
        setLandingLoading
    ] = useState(true)

    useEffect(() => {  
        getData(
            urls.parts.order.get_cart_item,
            setLandingData,
            setLandingLoading
        );
    }, []);

    if(isLandingLoading) 
        return (<p>Landing Loading</p>);

    if(!landingData) 
        return (<p>Landing Data incoming</p>);*/

  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody>
          <Landing></Landing>
          <LandingBody></LandingBody>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default LandingPage;
