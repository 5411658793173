import styled from 'styled-components';

const Container = styled.div`
  height: 70vh;
`;

const SubContainer = styled.div`
  background: #eee;
  height: 60vh;
`;

const Div = styled.div`
  background: #fff;
  width: 80%;
  height: 60vh;
  position: relative;
  margin: 0 auto;
`;

export {
  Container,
  SubContainer,
  Div
};
