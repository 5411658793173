const postData = async (api_url, data) => {
  console.log('api_url');
  console.log(api_url);
  console.log('data');
  console.log(data);

  return await fetch(api_url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
  /*.then(res => {
            console.log("POST: URL @");
            console.log(api_url);
            console.log("POST: response");
            console.log(res);
            return res;
        })
        .catch(err => {
            console.log("POST: URL @");
            console.log(api_url);
            console.log(err);
        });*/
};

export default postData;
