import styled from 'styled-components';


const AboutImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: 500px;
  height: auto;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 5px 5px 20px #555555;
`;

const CarouselImg = styled.img`
  display: block;
  float: right;
  width: auto;
  height: 25vh;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 5px 5px 20px #555555;
`;

const HoursImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  height: 400px;
  width: auto;
  border-radius: 25px 25px 25px 25px;
  padding-bottom: 50px;
`;

const ContactImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: auto;
  height: 350px;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 5px 5px 20px #555555;
`;


const LandingImg = styled.img`
  width: auto;
  height: 15vh;
`;

const ServicesImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  height: 400px;
  width: auto;
  border-radius: 25px 25px 25px 25px;
  padding-top: 50px;
  padding-right: 165px;
`;

/*Same as OrderHeaderImg*/
const ScheduleHeaderImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  height: 180px;
  width: auto;
  border-radius: 25px 25px 25px 25px;
  padding-right: 165px;
`;

/*Same as ScheduleHeaderImg*/
const OrderHeaderImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  height: 180px;
  width: auto;
  border-radius: 25px 25px 25px 25px;
  padding-right: 165px;
`;


/*Same as PartsCatalogImg */
const PartsCatalogHeaderImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: 250px;
  height: auto;
`;

/*Same as PartsCatalogHeaderImg*/
const PartsCatalogImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: 250px;
  height: auto;
`;

const PartsImg = styled.img`
  display: block;
  margin: auto;
  float: right;
  width: auto;
  height: 15vh;
  border-radius: 25px;
`;

export {
    AboutImg,
    CarouselImg,
    HoursImg,
    ContactImg,
    LandingImg,
    ServicesImg,
    ScheduleHeaderImg,
    OrderHeaderImg,
    PartsCatalogHeaderImg,
    PartsCatalogImg,
    PartsImg,
};

