import React, { useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import fleet from '../../assets/img/fleet.jpg';
import vmac from '../../assets/img/vmac.png';

const MyNavBar = styled(Navbar)`
  font-family: Montserrat;
  background-color: #fff;
  height: 300px;
  border-bottom: 1px solid black;
`;

const Fleet = styled.div`
  width: 100%;
  max-width: 800px;
  height: auto;
  position: relative;
  z-index: 5;
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 220px;
  padding-bottom: 50px;
`;

const FleetImage = styled.img`
  width: 100%;
  height: 150px;
`;

const NavBarLink = styled(Link)`
  color: #000;
`;

const MyNavItem = styled(NavItem)`
  margin-left: 50px;
  font-size: 50px;
  font-family: Montserrat;
`;

const MyNavBarLinks = styled.div`
  margin-left: 100px;
  margin-right: auto;
`;

function NavigationBar() {
  const navBarProps = { 
    light: true, expand: 'md', sticky: 'top' 
  };

  const [isOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggle = () => setIsDropdownOpen(!isDropdownOpen);

  return (
      <MyNavBar {...navBarProps}>
        <NavbarBrand href="/">
          <Fleet>
          <FleetImage src={fleet} />
          </Fleet>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <MyNavBarLinks>
          <Nav className="ml-auto" navbar>
            <MyNavItem>
              <NavBarLink to="/">Home</NavBarLink>
            </MyNavItem>
            <MyNavItem>
              <NavBarLink to="/services">Services</NavBarLink>
            </MyNavItem>
            <MyNavItem>
              <NavBarLink to="/parts">Parts</NavBarLink>
            </MyNavItem>
            <MyNavItem>
              <NavBarLink to="/about">About</NavBarLink>
            </MyNavItem>
            <MyNavItem>
              <NavBarLink to="/contact">Contact</NavBarLink>
            </MyNavItem>
          </Nav>
          </MyNavBarLinks>
        </Collapse>
        <img src={vmac} style={{ marginRight: '50px'}}/>
      </MyNavBar>
  );
}

export default NavigationBar;
