import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

function About(props) {
  const [aboutData, setAboutData] = useState(null);

  const [isAboutLoading, setAboutLoading] = useState(true);

  useEffect(() => {
    getData(urls.about.about, setAboutData, setAboutLoading);
  }, []);

  const aboutFillIn = (
    <InfoDiv>
      <ImgDiv></ImgDiv>
      <InfoTextDiv>
        <InfoText></InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );

  if (isAboutLoading) return aboutFillIn;

  if (!aboutData) return aboutFillIn;

  return (
    <InfoDiv>
      <ImgDiv>
        <Image fileName={aboutData[0].img.src} alt={aboutData[0].img.alt} className={aboutData[0].img.class_name} />
      </ImgDiv>
      <InfoTextDiv>
        <InfoHeader>{aboutData[0].text.header}</InfoHeader>
        <InfoText>
          <p>{aboutData[0].text.body.description_one}</p>
          <p>{aboutData[0].text.body.description_two}</p>
        </InfoText>
      </InfoTextDiv>
    </InfoDiv>
  );
}

export default About;
