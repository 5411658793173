import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import NavigationBar from '../common/navbar.js';
import About from '../common/about.js';
import Hours from '../common/hours.js';
import AboutCarousel from './carousel.js';
import Footer from '../common/footer.js';

import { PageBackground,
    PageBodyBackground,
    PageBody 
} from '../common/page_body.js';

function AboutPage(props) {

  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
        <PageBodyBackground>
          <PageBody>

            <About></About>
            <AboutCarousel></AboutCarousel>
            <Hours></Hours>

          </PageBody>
        </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default AboutPage;
