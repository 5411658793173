import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Page404,
  Page500,
  Landing,
  ServicesPage,
  SchedulePage,
  ScheduleConfirmationPage,
  PartsPage,
  OrderPage,
  TransactionPage,
  TransactionConfirmationPage,
  AboutPage,
  ContactPage,
} from './pages';
import './scss/style.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" name="Landing Page" element={<Landing />} />

        <Route exact path="/services" name="ServicesPage" element={<ServicesPage />} />

        <Route exact path="/services/schedule" name="SchedulePage" element={<SchedulePage />} />

        <Route
          exact
          path="/services/schedule/confirmation"
          name="ScheduleConfirmationPage"
          element={<ScheduleConfirmationPage />}
        />

        <Route exact path="/parts" name="PartsPage" element={<PartsPage />} />

        <Route exact path="/parts/order" name="OrderPage" element={<OrderPage />} />

        <Route exact path="/parts/order/transaction" name="TransactionPage" element={<TransactionPage />} />

        <Route
          exact
          path="/parts/order/transaction/confirmation"
          name="TransactionConfirmationPage"
          element={<TransactionConfirmationPage />}
        />

        <Route exact path="/about" name="AboutPage" element={<AboutPage />} />

        <Route exact path="/contact" name="ContactPage" element={<ContactPage />} />

        <Route exact path="/404" name="Page 404" element={<Page404 />} />

        <Route exact path="/500" name="Page 500" element={<Page500 />} />
      </Routes>
    </Router>
  );
}

export default App;
