import React from 'react';
import styled from 'styled-components';

const MyFooter = styled.footer`
  padding: 15px;
  background-color: #fff;
`;

function Footer() {
  return (      
      <MyFooter className="container-fluid text-center">
        <a href="#myPage" title="To Top">
          Back To Top
        </a>
        <p>T&B Fleet Services</p>
      </MyFooter>
  );
}

export default Footer;
