import React, { useEffect, useState } from 'react';

import NavigationBar from '../common/navbar.js';
import Services from './services.js';
import RentalTrucks from './rental-trucks.js';
import ServicesCarousel from './carousel.js';
import ScheduleButton from '../common/schedule_button.js';
import Footer from '../common/footer.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';

import { BlankSpace } from '../common/subtext.js';

function ServicesPage(props) {
  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody>
          <Services></Services>
          <ScheduleButton></ScheduleButton>
          <RentalTrucks></RentalTrucks>
          <ServicesCarousel></ServicesCarousel>

          <BlankSpace></BlankSpace>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default ServicesPage;
