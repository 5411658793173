import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import NavigationBar from '../common/navbar.js';
import Footer from '../common/footer.js';

import ContactUsButton from '../../components/contact-us-button.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';
import { Container, SubContainer, Div } from '../common/one_column.js';

import { InfoHeader, InfoText } from '../common/two_columns.js';

import getData from '../common/fetch_data.js';
import postData from '../common/post_data.js';
import urls from '../common/urls.js';

const PayDiv = styled.div`
  height: 45vh;
  margin-top: 2.5vh;
  margin-right: 2.5%;
  border-radius: 25px;
  background: #fff;
`;

const CardInfoHeader = styled.div`
  width: 100%;
  float: left;
  height: 8vh;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  border-radius: 25px;
  background: #fff;
  padding-top: 1vh;
`;

const D = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 40vh;
  padding-top: 5vh;
`;

const D2 = styled.div`
  display: flex;
  justify-content: center;
`;

function ConfirmationPage(props) {
  const navigation = useNavigate();

  const [cartData, setCartData] = useState(null);

  const [isCartLoading, setCartLoading] = useState(true);

  useEffect(() => {
    getData(urls.parts.order.get_cart, setCartData, setCartLoading);
  }, []);

  const postCardDetails = (event) => {
    event.preventDefault();
    /*const data = {
            expiration_date: event.target.expiration_date.value,
        };*/

    //post confirmation of booking
    navigation('/services/schedule');
  };

  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody>
          <Container>
            <SubContainer>
              <Div>
                <div style={{ height: '10vh', width: '100%', border: '1px solid blue' }}></div>
                <h1>Schedule Confirmation</h1>
              </Div>
            </SubContainer>
          </Container>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default ConfirmationPage;
