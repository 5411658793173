import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CardElement, PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import NavigationBar from '../common/navbar.js';
import Footer from '../common/footer.js';

import ContactUsButton from '../../components/contact-us-button.js';

import { PageBackground, PageBodyBackground, PageBody } from '../common/page_body.js';

import { Container, SubContainer, Div } from '../common/one_column.js';

import { InfoHeader, InfoText, InfoTextDiv, InfoDiv } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

import getData from '../common/fetch_data.js';
import postData from '../common/post_data.js';
import urls from '../common/urls.js';

const StripeComponent = (props) => {
  console.log('Stripe Component props');
  console.log(props);

  const stripe = useStripe();
  const elements = useElements();

  const navigation = useNavigate();

  const [firstNameValue, setFirstNameValue] = useState('');

  const [lastNameValue, setLastNameValue] = useState('');

  const handleStripeWrapper = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { error, clientSecret } = await fetch(urls.parts.order.stripe_processing, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodType: 'card',
        currency: 'cad',
      }),
    }).then((res) => res.json());

    console.log('clientSecret');
    console.log(clientSecret);

    if (error) return;

    const { e, paymentIntent } = await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: 'Jenny Rosen',
          },
        },
      })
      .then((res) => console.log(res));

    if (e) return;

    console.log('paymentIntent');
    console.log(paymentIntent);

    const data = {
      confirmation_key: 'confirmation_key',
      confirmed: true,
      in_house: props,
    };

    postData(urls.parts.order.place_transaction, data)
      .then((res) => {
        console.log('urls.home.set_cookie');
        console.log(urls.home.set_cookie);
        if (res.status != 200) {
          console.log('Place Order status');
          console.log(res.status);
        }
        navigation('/parts/order/transaction/confirmation');
      })
      .catch((err) => {
        console.log('Place Order error');
        console.log(err);
      });

    /*const data = {
            paymentIntent.status,
            paymentIntent.id,
            paymentIntent.succeeded,*/
  };

  return (
    <form autoComplete="off" onSubmit={handleStripeWrapper}>
      <Label>
        First Name
        <br></br>
        <Input
          id="first_name"
          name="first_name"
          type="text"
          value={firstNameValue}
          placeholder="John"
          onChange={(e) => setFirstNameValue(e.target.value)}
        ></Input>
      </Label>

      <Label>
        Last Name
        <Input
          id="last_name"
          name="last_name"
          type="text"
          value={lastNameValue}
          placeholder="Smith"
          onChange={(e) => setLastNameValue(e.target.value)}
        ></Input>
      </Label>

      <Label>
        Card Information
        <CardElement id="card" />
      </Label>

      <ContactUsButton type="submit" disabled={firstNameValue == '' || lastNameValue == ''}>
        Submit
      </ContactUsButton>
    </form>
  );
};

const StripeWrapper = (props) => {
  const stripePromise = loadStripe(
    'pk_test_51QP4otJe2Hxc9syCerEYzcI4qd5e0eCMI5MGe7jHKxb0gqA1hcNKtjEQ9aJuSSpU0szfKNnCu3GVtWm58MAt35fE00TcjdEouX',
  );

  return (
    <Elements stripe={stripePromise}>
      <StripeComponent props={props} />
    </Elements>
  );
};

const PayDiv = styled.div`
  height: 45vh;
  margin-right: 2.5%;
  border-radius: 25px;
  background: #fff;
`;

const CustomInfoDiv = styled(InfoDiv)`
  height: 15vh;
`;

const CustomInfoHeader = styled(InfoHeader)`
  border-radius: 25px;
`;

const CardInfoHeader = styled.div`
  width: 45%;
  float: left;
  height: 8vh;
  margin: 0 auto;
  margin-top: 1vh;
  text-align: center;
  vertical-align: middle;
  border-radius: 25px;
  background: #fff;
  padding-top: 1vh;
`;

const D = styled.div`
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 40vh;
  padding-top: 5vh;
`;

const D2 = styled.div`
  display: flex;
  justify-content: center;
  height: 40vh;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  box-sizing: border-box;
  align: middle,
  margin-left: 15px,
`;

const Label = styled.label`
  width: 100%;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
  background: #fff;
`;

const D4 = styled.div`
  width: 80%;
  border: 2px solid #eee;
  height: 5vh;
  position: relative;
  margin: 0 auto;
  padding-top: 15px;
  border-radius: 25px;
  margin-top: 1vh;
`;

function TransactionPage(props) {
  const navigation = useNavigate();

  const [transactionData, setTransactionData] = useState(null);

  const [isTransactionLoading, setTransactionLoading] = useState(true);

  const [firstNameValue, setFirstNameValue] = useState('');

  const [lastNameValue, setLastNameValue] = useState('');

  const [creditCardNumberValue, setCreditCardNumberValue] = useState('');

  const [ccvValue, setCcvValue] = useState('');

  const [expirationDateValue, setExpirationDateValue] = useState('');

  useEffect(() => {
    getData(urls.parts.order.get_price, setTransactionData, setTransactionLoading);
  }, []);

  if (isTransactionLoading) return <p>Transaction Loading</p>;

  if (!transactionData) return <p>Transaction Data incoming</p>;

  const postCardDetails = (event) => {
    event.preventDefault();

    //call the payment processing front-end here

    //post confirmation of transaction
    const data = {
      confirmation_key: 'confirmation_key',
      confirmed: true,
      in_house: transactionData.in_house,
    };

    /*postData(
            urls.parts.order.process_transaction,
            data
        ).then((res) => {
            console.log("&&&& res &&&&");
            console.log(res.json());
        });*/
  };

  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
      <PageBodyBackground>
        <PageBody style={{ height: '100vh' }}>
          <Container
            style={{
              height: '90vh',
              background: '#eee',
            }}
          >
            <SubContainer
              style={{
                height: '90vh',
                background: '#eee',
              }}
            >
              <div
                style={{
                  height: '15vh',
                  width: '100%',
                  background: '#fff',
                  'padding-bottom': '50px',
                }}
              >
                <InfoTextDiv>
                  <InfoHeader
                    style={{
                      'padding-top': '50px',
                      'padding-left': '100px',
                    }}
                  >
                    Payment Details
                  </InfoHeader>
                </InfoTextDiv>
              </div>
              <CustomBlankSpace></CustomBlankSpace>

              <Div
                style={{
                  height: '70vh',
                  background: '#eee',
                }}
              >
                <D2>
                  <CardInfoHeader>
                    <InfoHeader>Pricing Details</InfoHeader>

                    <BlankSpace></BlankSpace>

                    <CustomInfoHeader
                      style={{
                        height: '33vh',
                        background: '#fff',
                        'font-size': '25px',
                        'font-family': 'Montserrat',
                        'padding-top': '25px',
                        'font-weight': 'normal',
                        overflow: 'auto',
                      }}
                    >
                      <div>
                        {transactionData.prices.map((item, i) => (
                          <D4 key={i}>
                            <div
                              style={{
                                'text-align': 'left',
                                'padding-left': '15px',
                                float: 'left',
                              }}
                            >
                              {item.serial_number}:
                            </div>
                            <div
                              style={{
                                'text-align': 'left',
                                'padding-right': '15px',
                                float: 'right',
                              }}
                            >
                              {item.quantity} x ${item.price} = ${item.total}
                            </div>
                          </D4>
                        ))}

                        <br></br>
                      </div>
                    </CustomInfoHeader>

                    <CustomInfoHeader
                      style={{
                        background: '#fff',
                        height: '16vh',
                        'font-size': '25px',
                        'font-family': 'Montserrat',
                        'font-weight': 'normal',
                      }}
                    >
                      <div
                        style={{
                          'padding-top': '15px',
                        }}
                      >
                        <div
                          style={{
                            'padding-left': '15px',
                            float: 'left',
                            'text-align': 'right',
                          }}
                        >
                          cost of goods:
                          <br></br>
                          shipping cost:
                          <br></br>
                          subtotal:
                          <br></br>
                          taxes:
                          <br></br>
                          <br></br>
                          total cost:
                        </div>
                        <div
                          style={{
                            'padding-right': '15px',
                            float: 'right',
                            'text-align': 'left',
                          }}
                        >
                          ${transactionData.costs.sum_of_cart}
                          <br></br>${transactionData.costs.shipping_cost}
                          <br></br>${transactionData.costs.subtotal}
                          <br></br>${transactionData.costs.taxes}
                          <br></br>
                          <br></br>${transactionData.costs.taxed_total_cost}
                        </div>
                      </div>
                    </CustomInfoHeader>
                  </CardInfoHeader>

                  <CardInfoHeader>
                    <InfoHeader>Payment Information</InfoHeader>

                    <BlankSpace></BlankSpace>

                    <CustomInfoHeader
                      style={{
                        height: '50vh',
                        background: '#fff',
                        'font-size': '25px',
                        'font-family': 'Montserrat',
                        'padding-top': '25px',
                        'font-weight': 'normal',
                      }}
                    >
                      <StripeWrapper props={transactionData.in_house}></StripeWrapper>

                      <form autoComplete="off" method="post" onSubmit={postCardDetails}>
                        <Label>
                          First Name
                          <br></br>
                          <Input
                            id="first_name"
                            name="first_name"
                            type="text"
                            value={firstNameValue}
                            placeholder="John"
                            onChange={(e) => setFirstNameValue(e.target.value)}
                          ></Input>
                        </Label>
                        <br></br>
                        <Label>
                          Last Name
                          <Input
                            id="last_name"
                            name="last_name"
                            type="text"
                            value={lastNameValue}
                            placeholder="Smith"
                            onChange={(e) => setLastNameValue(e.target.value)}
                          ></Input>
                        </Label>
                        <br></br>
                        <Label>
                          Credit Card Number
                          <Input
                            id="credit_card_number"
                            name="credit_card_number"
                            type="text"
                            value={creditCardNumberValue}
                            placeholder="XXXX-XXXX-XXXX-XXXX"
                            onChange={(e) => setCreditCardNumberValue(e.target.value)}
                          ></Input>
                        </Label>
                        <br></br>
                        <Label>
                          CVV
                          <br></br>
                          <Input
                            id="cvv"
                            name="cvv"
                            type="text"
                            value={ccvValue}
                            placeholder="XXX"
                            onChange={(e) => setCcvValue(e.target.value)}
                          ></Input>
                        </Label>
                        <br></br>
                        <Label>
                          Expiration Date
                          <Input
                            id="expiration_date"
                            name="expiration_date"
                            type="text"
                            value={expirationDateValue}
                            placeholder="XX/XX"
                            onChange={(e) => setExpirationDateValue(e.target.value)}
                          ></Input>
                        </Label>
                        <br></br>

                        <br></br>
                        <ContactUsButton
                          type="submit"
                          disabled={
                            firstNameValue == '' ||
                            lastNameValue == '' ||
                            creditCardNumberValue == '' ||
                            ccvValue == '' ||
                            expirationDateValue == ''
                          }
                        >
                          Submit
                        </ContactUsButton>
                      </form>
                    </CustomInfoHeader>
                  </CardInfoHeader>
                </D2>
              </Div>
            </SubContainer>
          </Container>
        </PageBody>
      </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default TransactionPage;
