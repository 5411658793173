import styled from 'styled-components';

const InfoDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  height 40vh;
  padding-top: 50px;
`;

const ImgDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 35%;
  float: left;
`;

const InfoTextDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 50%;
  float: left;
  padding-left: 50px;
`;

const InfoHeader = styled.p`
  font-family: Montserrat;
  font-size: 55px;
  color: #000;
  font-weight: bold;
`;

const InfoText = styled.p`
  font-family: Montserrat;
  color: #000;
  font-size: 25px;
`;

export {
  InfoDiv,
  ImgDiv,
  InfoTextDiv,
  InfoHeader,
  InfoText
};

