import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CarouselSlide from './carousel_slide.js';

const ContainerDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  background: #eee;
  height: 30vh;
`;

function Carousel(props) {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
  };

  return (
    <div style={{ width: '100%', background: '#eee' }}>
      <ContainerDiv>
        <Slider {...settings}>
          {props.data
            .filter((_, i) => i < props.data.length / 2)
            .map((_, i) => (
              <CarouselSlide key={i} img_one={props.data[i * 2]} img_two={props.data[i * 2 + 1]}></CarouselSlide>
            ))}
        </Slider>
      </ContainerDiv>
    </div>
  );
}

export default Carousel;
