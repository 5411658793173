import React from 'react';

import NavigationBar from '../common/navbar.js';
import Contact from '../common/contact.js';
import ScheduleButton from '../common/schedule_button.js';
import Hours from '../common/hours.js';
import Footer from '../common/footer.js';

import { PageBackground,
    PageBodyBackground,
    PageBody 
} from '../common/page_body.js';

function ContactPage(props) {

  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
        <PageBodyBackground>
          <PageBody>
            <Contact></Contact>
            <ScheduleButton></ScheduleButton>
            <Hours></Hours>
          </PageBody>
        </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default ContactPage;
