const https_protocol = 'https';
const http_protocol = 'http';

const domain = 'tbfleet.dev.domain';
const prod_domain = 'ec2-3-99-248-240.ca-central-1.compute.amazonaws.com';

const https_port = '1337';
const http_port = '8080';
const link_port = '3000';

const base_url = 
    https_protocol 
    + '://' 
    + prod_domain 
    + ':' 
    + https_port;

//const base_url = http_protocol + '://' + _domain + ':' + http_port;
//const _base_url = protocol + '://' + _domain + ':' + port;

const base_link = 
    https_protocol 
    + '://' 
    + prod_domain 
    + ':' 
    + https_port;

//const _base_link = protocol + '://' + 'www.tbfleetservices.ca';



const paths = {
  home: {
    set_cookie: '/set_cookie',
    landing: '/landing',
    body: '/subtext',
    carousel: '/carousel',
  },

  services: {
    services: '/services/services',
    carousel: '/services/carousel',
    /*Rental trucks*/
    schedule: {
      schedule: '/services/schedule',
      booking: '/services/schedule/booking',
      get_appointments: '/services/schedule/get_appointments',
      confirmation: '/services/schedule/confirmation',
    },
  },

  parts: {
    catalog_header: '/parts/catalog/header',
    catalog: '/parts/catalog',
    order: {
      order_link: '/parts/order',
      place_item: '/parts/order/place_item',
      get_cart: '/parts/order/get_cart',
      get_cart_item: '/parts/order/get_cart_item',
      place_order: '/parts/order/place_order',
      place_transaction: '/parts/order/transaction/place_transaction',
      remove_cart_item: '/parts/order/remove_cart_item',
      get_price: '/parts/order/transaction/get_price',
      stripe_processing: '/parts/order/transaction/stripe',
    },
    /*order button*/
  },

  about: {
    about: '/about/about',
    carousel: '/about/carousel',
  },

  component: {
    hours: '/component/hours',
    contact: '/component/contact',
    schedule_button: '/component/schedule_button',
  },
};

const urls = {
  home: {
    set_cookie: base_url + paths.home.set_cookie,
    landing: base_url + paths.home.landing,
    body: base_url + paths.home.body,
    carousel: base_url + paths.home.carousel,
  },

  services: {
    services: base_url + paths.services.services,
    carousel: base_url + paths.services.carousel,
    /*Rental trucks*/
    schedule: {
      schedule_link: base_link + paths.services.schedule.schedule,

      booking: base_url + paths.services.schedule.booking,

      get_appointments: base_url + paths.services.schedule.get_appointments,

      confirmation: base_url + paths.services.schedule.confirmation,
    },
  },

  parts: {
    catalog_header: base_url + paths.parts.catalog_header,
    catalog: base_url + paths.parts.catalog,
    order: {
      order_link: base_link + paths.parts.order.order_link,

      place_item: base_url + paths.parts.order.place_item,

      get_cart: base_url + paths.parts.order.get_cart,

      get_cart_item: base_url + paths.parts.order.get_cart_item,

      place_order: base_url + paths.parts.order.place_order,

      place_transaction: base_url + paths.parts.order.place_transaction,

      remove_cart_item: base_url + paths.parts.order.remove_cart_item,

      get_price: base_url + paths.parts.order.get_price,

      stripe_processing: base_url + paths.parts.order.stripe_processing,
    },
    /*order button*/
  },

  about: {
    about: base_url + paths.about.about,
    carousel: base_url + paths.about.carousel,
  },

  component: {
    hours: base_url + paths.component.hours,
    contact: base_url + paths.component.contact,
    schedule_button: base_url + paths.component.schedule_button,
  },
};

console.log("services schedule");
console.log(urls.services.schedule.schedule_link);

export default urls;
