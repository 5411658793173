import React from 'react';
import useImage from '../common/use_image.js';

import {
    AboutImg,
    CarouselImg,
    HoursImg,
    ContactImg,
    LandingImg,
    ServicesImg,
    ScheduleHeaderImg,
    OrderHeaderImg,
    PartsCatalogHeaderImg,
    PartsCatalogImg,
    PartsImg,
} from './img_styles.js';

const img_classes = {
    about: AboutImg,
    carousel: CarouselImg,
    contact: ContactImg,
    hours: HoursImg,
    landing: LandingImg,
    services: ServicesImg,
    schedule_header: ScheduleHeaderImg,
    parts_header: PartsCatalogHeaderImg,
    parts_catalog: PartsCatalogImg,
    parts: PartsImg,
    order_header: OrderHeaderImg,
};

const Image = ({ fileName, alt, className }) => {

    let ClassName = img_classes[className];
    const { loading, error, image } = useImage(fileName)

    if(error) return (<p>{alt}</p>);

    return (
    <div>
        {loading ? 
            (<p> loading </p>):
            (<ClassName src={image} alt={alt}/>)}
    </div>
    );
};

export default Image;
