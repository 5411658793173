import React, { useEffect, useState } from 'react';

const getData = async (api_url, setData, setLoading) => {
  console.log('document.cookie');
  console.log(document.cookie);
  console.log('localstorage.getItem(hello_six)');
  console.log(localStorage.getItem('hello_six'));
  const get_response = await fetch(api_url, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  })
    .then((res) => res.json())
    .then((res) => {
      console.log('GET: URL @');
      console.log(api_url);
      console.log('GET: response');
      console.log(res);
      return res;
    })
    .then((res) => setData(res))
    .then((res) => setLoading(false))
    .catch((err) => {
      console.log('GET: URL @');
      console.log(api_url);
      console.log(err);
    });
};

export default getData;
