import React from 'react';
import styled from 'styled-components';

/*import services from '../../assets/services.png';*/
import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

const CustomInfoDiv = styled(InfoDiv)`
  height: 15vh;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

const services_header = {
  type: 'header',
  page: 'schedule',
  img: {
    src: 'services.png',
    alt: 'Services - Pic One',
    type: 'schedule_header',
  },
  text: {
    header: 'Services Availability',
    subheader: 'View Calendar below to see openings',
  },
};

function ScheduleHeader(props) {
  return (
    <div>
      <CustomInfoDiv>
        <ImgDiv>
          <Image
            fileName={services_header.img.src}
            alt={services_header.img.alt}
            className={services_header.img.type}
          />
        </ImgDiv>
        <InfoTextDiv>
          <InfoHeader>{services_header.text.header}</InfoHeader>
          <InfoText>
            <p>{services_header.text.subheader}</p>
          </InfoText>
        </InfoTextDiv>
      </CustomInfoDiv>
      <CustomBlankSpace></CustomBlankSpace>
    </div>
  );
}

export default ScheduleHeader;
