import styled from 'styled-components';

const PageBackground = styled.div`
  background: #000;
`;

const PageBodyBackground = styled.div`
  width: 80%;
  position: relative;
  margin: 0 auto;
  background: #32CD32;
`;

const PageBody = styled.div`
  width: 98%;
  position: relative;
  margin: 0 auto;
  background: #fff;
`;

export { 
  PageBackground,
  PageBodyBackground,
  PageBody
};
  
