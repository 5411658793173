import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RenderHtml from 'react-native-render-html';

import Image from '../common/image.js';

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

const CustomInfoDiv = styled(InfoDiv)`
  height: 30vh;
`;

function Contact(props) {
  const [contactData, setContactData] = useState(null);

  const [isContactLoading, setContactLoading] = useState(true);

  useEffect(() => {
    getData(urls.component.contact, setContactData, setContactLoading);
  }, []);

  const contactFillIn = (
    <CustomInfoDiv>
      <ImgDiv></ImgDiv>
      <InfoTextDiv>
        <InfoText></InfoText>
      </InfoTextDiv>
    </CustomInfoDiv>
  );

  if (isContactLoading) return contactFillIn;

  if (!contactData) return contactFillIn;

  const entity_to_char = (str) => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec);
    });
  };

  return (
    <CustomInfoDiv>
      <ImgDiv>
        <Image
          fileName={contactData[0].img.src}
          alt={contactData[0].img.alt}
          className={contactData[0].img.class_name}
        />
      </ImgDiv>
      <InfoTextDiv>
        <InfoHeader>{contactData[0].text.header}</InfoHeader>
        <InfoText>
          <p>
            {contactData[0].text.body.address}
            <br />
            {contactData[0].text.body.municipality}
            <br />
            {contactData[0].text.body.postal_code}
          </p>
          <p>{contactData[0].text.body.phone_number}</p>
          <p>{entity_to_char(contactData[0].text.body.email)}</p>
        </InfoText>
      </InfoTextDiv>
    </CustomInfoDiv>
  );
}

export default Contact;
