import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ContactUsButton from '../../components/contact-us-button';

import { Div, InfoDiv, Header, ButtonDiv, BlankSpace } from '../common/button.js';

import getData from '../common/fetch_data.js';
import urls from '../common/urls.js';

const data = {
  text: {
    header: 'Order Confirmation',
    subheader: 'View Current Shopping Cart',
  },
};

function OrderButton(props) {
  /*const [
        cartData, 
        setCartData
    ] = useState(null)

    const [
        isCartLoading, 
        setCartLoading
    ] = useState(true)

    useEffect(() => {  
        getData(
            urls.parts.order.get_cart,
            setCartData,
            setCartLoading
        );
    }, []);*/

  return (
    <div style={{ background: '#fff' }}>
      <Div>
        <InfoDiv>
          <Header>{data.text.header}</Header>
        </InfoDiv>
      </Div>
      <Div>
        <InfoDiv>
          <Link to={urls.parts.order.order_link}>
            <ButtonDiv>
              <ContactUsButton>{data.text.subheader}</ContactUsButton>
            </ButtonDiv>
          </Link>
        </InfoDiv>
      </Div>
      <BlankSpace style={{ background: '#eee' }}></BlankSpace>
    </div>
  );
}

export default OrderButton;
