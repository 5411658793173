import React from 'react';
import styled from 'styled-components';

/*import services from '../../assets/services.png';*/

import { InfoDiv, ImgDiv, InfoTextDiv, InfoHeader, InfoText } from '../common/two_columns.js';

import { BlankSpace } from '../common/subtext.js';

const CustomInfoDiv = styled(InfoDiv)`
  height: 15vh;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  float: right;
  height: 180px;
  width: auto;
  border-radius: 25px 25px 25px 25px;
  padding-right: 165px;
`;

const CustomBlankSpace = styled(BlankSpace)`
  border-top: 10px solid #eee;
  border-bottom: 10px solid #eee;
`;

/*
        <ImgDiv>
          <Img src={services} alt="Services" />
        </ImgDiv>
*/
function OrderHeader(props) {
  return (
    <div>
      <CustomInfoDiv>
        <ImgDiv></ImgDiv>
        <InfoTextDiv>
          <InfoHeader>Shipping Details</InfoHeader>
          <InfoText>
            <p>Place shipping details in form</p>
            <p>Please review and confirm parts for order</p>
          </InfoText>
        </InfoTextDiv>
      </CustomInfoDiv>
      <CustomBlankSpace></CustomBlankSpace>
    </div>
  );
}

export default OrderHeader;
