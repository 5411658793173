import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from '../../common/carousel.js';

import getData from '../../common/fetch_data.js';
import urls from '../../common/urls.js';

const ContainerDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 90%;
  background: #eee;
  height: 30vh;
`;

function HomeCarousel(props) {
  const [homeCarouselData, setHomeCarouselData] = useState(null);

  const [isHomeCarouselLoading, setHomeCarouselLoading] = useState(true);

  useEffect(() => {
    getData(urls.home.carousel, setHomeCarouselData, setHomeCarouselLoading);
  }, []);

  const carouselFillIn = <ContainerDiv></ContainerDiv>;

  if (isHomeCarouselLoading) return carouselFillIn;

  if (!homeCarouselData) return carouselFillIn;

  return <Carousel data={homeCarouselData[0].imgs}></Carousel>;
}

export default HomeCarousel;
