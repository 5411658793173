import React from 'react';

import NavigationBar from '../common/navbar.js';
import OrderHeader from './header.js';
import ShoppingCart from './shopping_cart.js';
import Footer from '../common/footer.js';

import { PageBackground,
         PageBodyBackground,
         PageBody 
} from '../common/page_body.js';

function OrderPage(props) {
  return (
    <PageBackground id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">
      <NavigationBar></NavigationBar>
        <PageBodyBackground>
          <PageBody>
            <OrderHeader></OrderHeader>
            <ShoppingCart></ShoppingCart>
          </PageBody>
        </PageBodyBackground>
      <Footer></Footer>
    </PageBackground>
  );
}

export default OrderPage;
